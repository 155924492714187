import { Modal as MuiModal, Box as MuiBox, styled } from "@mui/material";

export const Modal = styled(MuiModal)(
  () => `
    &.MuiModal-root {
      z-index: 10001;
    }
  `
);

export const ModalInner = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      margin: 0;

      ${[breakpoints.up("sm")]} {
        margin: 0 auto;
        width: 550px;
      }

      ${[breakpoints.up("md")]} { 
        width: 850px;
      }

      ${[breakpoints.up("xl")]} { 
        width: 1000px;
      }
    }
  `
);
