import { useCallback } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { useTranslation } from "react-i18next";
import { ContactPerson } from "api/models";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonsWrapper,
  ContainedButton,
  FieldLine,
  TextInput,
  TitleInput
} from "shared/components";
import { useSyncContactPersonTitle } from "shared/hooks";
import { ContactPersonModalFormProps } from "./ContactPersonModalForm.props";
import { IconButton, makeSummaryStyles } from "./ContactPersonModalForm.styles";
import { emailValidator, phoneValidator, nameValidator } from "./ContactPersonModalForm.validation";

export const ContactPersonModalForm = ({
  initialState,
  onClose,
  onSubmit
}: ContactPersonModalFormProps) => {
  const { t } = useTranslation();

  const syncContactPersonTitle = useSyncContactPersonTitle();

  const handleFormSubmit = useCallback(
    (values: ContactPerson) => {
      return onSubmit(values);
    },
    [onSubmit]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Form
      initialValues={initialState}
      onSubmit={handleFormSubmit}
      render={({ dirty, handleSubmit, submitErrors, form, submitting }) => (
        <Accordion expanded={true}>
          <AccordionSummary sx={makeSummaryStyles()}>
            <Field
              name="title"
              render={({
                input: { value, onChange, onBlur, onFocus },
                meta: { error, touched, modifiedSinceLastSubmit }
              }) => (
                <TitleInput
                  placeholder={t("featuresModule.contactPerson.form.title")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  error={
                    (error || (submitErrors?.["title"] && !modifiedSinceLastSubmit)) && touched
                  }
                />
              )}
            />
            <ButtonsWrapper>
              <IconButton disabled={submitting} onClick={handleClose} />
            </ButtonsWrapper>
          </AccordionSummary>
          <AccordionDetails>
            <FieldLine>
              <Field
                name="email"
                validate={emailValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    required
                    label={t("featuresModule.contactPerson.form.email")}
                    placeholder={t("global.placeholders.email")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["email"] && !modifiedSinceLastSubmit)) && touched
                    }
                    errorMessage={t(submitErrors?.["email"] ?? error)}
                  />
                )}
              />
              <Field
                name="phone"
                validate={phoneValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    label={t("featuresModule.contactPerson.form.phone")}
                    placeholder={t("global.placeholders.phone")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["phone"] && !modifiedSinceLastSubmit)) && touched
                    }
                    errorMessage={t(submitErrors?.["phone"] ?? error)}
                  />
                )}
              />
            </FieldLine>
            <FieldLine>
              <Field
                name="firstName"
                validate={nameValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    required
                    label={t("featuresModule.contactPerson.form.firstName")}
                    placeholder={t("global.placeholders.firstName")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["firstName"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["firstName"] ?? error)}
                  />
                )}
              />
              <Field
                name="lastName"
                validate={nameValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    required
                    label={t("featuresModule.contactPerson.form.lastName")}
                    placeholder={t("global.placeholders.lastName")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["lastName"] && !modifiedSinceLastSubmit)) && touched
                    }
                    errorMessage={t(submitErrors?.["lastName"] ?? error)}
                  />
                )}
              />
            </FieldLine>
            <ContainedButton
              type="submit"
              disabled={!dirty}
              loading={submitting}
              onClick={handleSubmit}
            >
              {t("featuresModule.contactPerson.form.saveButton")}
            </ContainedButton>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => syncContactPersonTitle(values as ContactPerson, form)}
            />
          </AccordionDetails>
        </Accordion>
      )}
    />
  );
};
