import { useCallback } from "react";
import { ContactPerson } from "api/models";
import { ContactPersonModalProps } from "./ContactPersonModal.props";
import { Modal, ModalInner } from "./ContactPersonModal.styles";
import { ContactPersonModalForm } from "../ContactPersonModalForm";
import { useCreateContactPerson } from "../../hooks";

const FORM_INITIAL_STATE: ContactPerson = {
  contactPersonId: 0,
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  title: ""
};

export const ContactPersonModal = ({ open, onClose, onSubmit }: ContactPersonModalProps) => {
  const { submit: submitMethod, submitting } = useCreateContactPerson();

  const handleFormSubmit = useCallback(
    async (values: ContactPerson) => {
      const { data, error } = await submitMethod(values);
      if (error) return error;

      onSubmit(data);
    },
    [submitMethod, onSubmit]
  );

  const handleClose = useCallback(() => {
    if (!submitting) onClose();
  }, [submitting, onClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalInner>
        <ContactPersonModalForm
          initialState={FORM_INITIAL_STATE}
          onClose={handleClose}
          onSubmit={handleFormSubmit}
        />
      </ModalInner>
    </Modal>
  );
};
