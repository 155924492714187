import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContactPerson, PostContactPersonRequest } from "api/models";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { FormProps } from "react-final-form";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { postContactPerson } from "shared/api";

export const useCreateContactPerson = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: createContactPerson } = useMutation<
    ContactPerson,
    AxiosError<{ message: string }>,
    PostContactPersonRequest
  >(postContactPerson);

  const executeCreateContactPerson: FormProps<ContactPerson, ContactPerson>["onSubmit"] =
    useCallback(
      async (values) => {
        try {
          await createContactPerson({
            shopId: selectedShopId!,
            title: values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone || undefined
          });

          queryClient.invalidateQueries([queryName, selectedShopId, limit, offset]);

          showNotification("settingsModule.contactPersons.createSuccessMessage", "success");
        } catch (error) {
          return formatError(error as NetworkError);
        }
      },
      [selectedShopId, queryName, limit, offset, showNotification]
    );

  return executeCreateContactPerson;
};
