import { ContactPerson, PostContactPersonRequest } from "api/models";
import { apiClient } from "../utils";

export const postContactPerson = async ({
  email,
  firstName,
  lastName,
  phone,
  title,
  shopId
}: PostContactPersonRequest): Promise<ContactPerson> => {
  const { data } = await apiClient.post<ContactPerson>(`${shopId}/contact-persons`, {
    email,
    firstName,
    lastName,
    phone,
    title
  });

  return data;
};
