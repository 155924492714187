import {
  styled,
  Autocomplete as MuiAutocomplete,
  Box as MuiBox,
  Typography as MuiTypography,
  Paper as MuiPaper,
  ButtonProps,
  Button as MuiButton
} from "@mui/material";
import MuiChevron from "@mui/icons-material/ExpandMoreOutlined";
import MuiAdd from "@mui/icons-material/Add";
import { ContactPerson } from "api/models";

export const Chevron = styled(MuiChevron)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiSvgIcon-root {
      color: ${custom.white.light};

      &.MuiSelect-iconOpen {
        color: ${custom.white.main};
        transform: none;
      }
    }
  `
);

export const Autocomplete = styled(MuiAutocomplete<ContactPerson, false, true>)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    & > .MuiBox-root {
      
      & .MuiFormControl-root {
    
        & .MuiAutocomplete-inputRoot {
          padding: 0;

          & .MuiAutocomplete-input {
            padding: 16px 10px 16px 22px
          }

          & .MuiAutocomplete-popupIndicatorOpen {
            transform: none;

            & .MuiSvgIcon-root {
              color: ${custom.white.main};
            }
          }
        }
      }
    }
  `
);

export const Paper = styled(MuiPaper)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    }
  }) => `
    background-color: ${custom.background};
    box-shadow: ${custom.shadows.navigation};
    margin-top: 24px;
    padding: 24px 0 18px;

    & .MuiAutocomplete-listbox {
      padding: 0;

      & .MuiAutocomplete-option {
        color: ${custom.white.light};
        font-size: ${body1.desktop.fontSize};

        &:hover {
          background: none;
          color: ${custom.white.main};
        }

        &[aria-selected="true"] {
          background: none;
          color: ${custom.turquoise.main};

          &.Mui-focused {
            background: none;
          }

          &:hover {
            background: none;
            color: ${custom.white.main};
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: ${custom.grey.main};
    };

    &::-webkit-scrollbar-thumb {
      background: ${custom.white.light};
      borderRadius: 2px;

      &:hover {
        background: ${custom.white.main};
      }
    }

    & .MuiAutocomplete-loading {
      color: ${custom.white.light};
      padding: 6px 16px;
    }

    & .MuiAutocomplete-noOptions {
      color: ${custom.white.light};
    }
  `
);

export const Add = styled(MuiAdd)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiSvgIcon-root {
      color: ${custom.turquoise.main};
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  `
);

export const ButtonWrapper = styled(MuiBox)(
  () => `
    padding: 6px 16px;
  `
);

export const Button = styled((props: ButtonProps) => {
  return <MuiButton {...props} startIcon={<Add />} disableRipple />;
})(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    }
  }) => {
    return `
      color: ${custom.turquoise.main};
      font-size: ${body1.desktop.fontSize};
      text-transform: lowercase;
      padding: 0;

      &.Mui-disabled {
        color: ${custom.white.dark};

        & .MuiButton-icon {
          & .MuiSvgIcon-root {
            color: ${custom.white.dark};
          }
        }
      }

      :hover {
        background: none;
        color: ${custom.turquoise.light};

        & .MuiButton-icon {
          & .MuiSvgIcon-root {
            color: ${custom.turquoise.light};
          }
        }
      }
    `;
  }
);

export const ButtonInfoText = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.light};
      font-size: ${body2.desktop.fontSize};
      line-height: ${body2.desktop.lineHeight};
      margin-top: 6px;
    }
  `
);
