import { ContactPerson } from "api/models";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "shared/components";
import { DEFAULT_QUERY_LIMIT } from "config/constants";
import { ContactPersonSelectProps } from "./ContactPersonSelect.props";
import {
  Chevron,
  Autocomplete,
  Paper,
  Button,
  ButtonWrapper,
  ButtonInfoText
} from "./ContactPersonSelect.styles";
import { ContactPersonModal } from "../ContactPersonModal";
import { useContactPersonList } from "../../hooks";

export const ContactPersonSelect = ({
  value,
  onChange,
  onFocus,
  onBlur,
  error,
  errorMessage,
  marginBottom,
  required
}: ContactPersonSelectProps) => {
  const [opened, setOpened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  const { data, loading, refetch } = useContactPersonList();

  const { t } = useTranslation();

  const handleOpen = useCallback(() => {
    setOpened(true);
    refetch();
    if (onFocus) onFocus();
  }, [refetch, onFocus]);

  const handleClose = useCallback(() => {
    setOpened(false);
    if (onBlur) onBlur();
  }, [onBlur]);

  const handleChange = useCallback(
    (_: SyntheticEvent, value: ContactPerson) => {
      onChange(value);
    },
    [onChange]
  );

  const handleModalOpen = useCallback(() => {
    setModalOpened(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, []);

  const handleModalSubmit = useCallback(
    (value: ContactPerson) => {
      onChange(value);
      setModalOpened(false);
      if (onBlur) onBlur();
    },
    [onChange, onBlur]
  );

  const options = useMemo(() => [...data, value], [data, value]);

  const contactPersonsCountThresholdMet = data.length >= DEFAULT_QUERY_LIMIT;

  return (
    <>
      <Autocomplete
        options={options}
        value={value}
        open={opened}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        loadingText={t("featuresModule.contactPerson.loading")}
        noOptionsText={t("featuresModule.contactPerson.noOptions")}
        loading={loading}
        getOptionLabel={({ title }: ContactPerson) => title}
        isOptionEqualToValue={(option, selected) => {
          if (!data.length) return true;
          return option.contactPersonId === selected.contactPersonId;
        }}
        renderInput={(params) => (
          <TextInput
            {...params}
            required={required}
            placeholder={t("featuresModule.contactPerson.placeholder")}
            label={t("featuresModule.contactPerson.label")}
            error={error}
            errorMessage={errorMessage}
            marginBottom={marginBottom}
          />
        )}
        popupIcon={<Chevron />}
        disableClearable
        filterSelectedOptions
        PaperComponent={({ children }) => {
          return (
            <Paper>
              {children}
              {!loading && (
                <ButtonWrapper>
                  <Button
                    disabled={contactPersonsCountThresholdMet}
                    onMouseDown={handleModalOpen}
                    onKeyDown={handleModalOpen}
                  >
                    {t("featuresModule.contactPerson.addMoreButton")}
                  </Button>
                  {contactPersonsCountThresholdMet && (
                    <ButtonInfoText>
                      {t("featuresModule.contactPerson.buttonInfoText")}
                    </ButtonInfoText>
                  )}
                </ButtonWrapper>
              )}
            </Paper>
          );
        }}
      />
      <ContactPersonModal
        open={modalOpened}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};
