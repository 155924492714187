import { IconButtonProps, IconButton as MuiIconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const makeSummaryStyles = () => ({
  cursor: "default",
  "&:hover:not(.Mui-disabled)": {
    cursor: "default"
  }
});

export const IconButton = styled((props: IconButtonProps) => {
  return (
    <MuiIconButton {...props}>
      <CloseIcon />
    </MuiIconButton>
  );
})(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root { 
      color: ${custom.white.main};

      &.Mui-disabled {
        color: ${custom.white.dark};
      }
    }
  `
);
