import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContactPerson, PostContactPersonRequest } from "api/models";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { DEFAULT_QUERY_LIMIT } from "config/constants";
import { useNotifications, usePagination, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { postContactPerson } from "shared/api";

const queryName = "contactPersons";

export const useCreateContactPerson = () => {
  const { selectedShopId } = useSelectedShop();

  const { offset, limit } = usePagination(DEFAULT_QUERY_LIMIT);

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { isLoading, mutateAsync: createContactPerson } = useMutation<
    ContactPerson,
    AxiosError<{ message: string }>,
    PostContactPersonRequest
  >(postContactPerson);

  const executeCreateContactPerson = useCallback(
    async (values: ContactPerson) => {
      try {
        const response = await createContactPerson({
          shopId: selectedShopId!,
          title: values.title,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone || undefined
        });

        queryClient.invalidateQueries([queryName, selectedShopId, limit, offset]);

        showNotification("settingsModule.contactPersons.createSuccessMessage", "success");

        return {
          data: response
        };
      } catch (error) {
        return {
          error: formatError(error as NetworkError)
        };
      }
    },
    [selectedShopId, queryName, limit, offset, showNotification]
  );

  return { submit: executeCreateContactPerson, submitting: isLoading };
};
