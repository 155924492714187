import { useCallback } from "react";
import { FormApi } from "final-form";
import { ContactPerson } from "api/models";

// Fallback logic to set the contact person title field value
// based on the entered first and last name
export const useSyncContactPersonTitle = () => {
  return useCallback(
    (values: ContactPerson, form: FormApi<ContactPerson, Partial<ContactPerson>>) => {
      if (!form.getFieldState("title")?.visited && (values.firstName || values.lastName)) {
        form.change("title", `${values.firstName} ${values.lastName}`);
      }
    },
    []
  );
};
