import { SnackbarProvider } from "notistack";
import { PropsWithChildren } from "react";
import { NotificationAlert } from "../../utils/NotificationAlert";
import ErrorIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { useStyles } from "./NotificationWrapper.styles";

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        containerRoot: classes.root
      }}
      iconVariant={{
        default: <InfoIcon sx={{ marginInlineEnd: "10px" }} />,
        error: <ErrorIcon sx={{ marginInlineEnd: "10px" }} />,
        success: <SuccessIcon sx={{ marginInlineEnd: "10px" }} />,
        warning: <WarningAmberOutlinedIcon sx={{ marginInlineEnd: "10px" }} />
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      Components={{
        default: NotificationAlert,
        error: NotificationAlert,
        success: NotificationAlert,
        warning: NotificationAlert
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
